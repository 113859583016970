import barba from '@barba/core'
import axios from 'axios'
// import Layout from "../template-parts/layout";

// import ScrollMagic from 'scrollmagic'

class Barba {
  constructor () {
    this.initBarba()
  }

  initBarba () {
    //
    // Añadido para que cuando haga clic en cualquiera de las opciones del menú principal de Equipo
    // como recarga la página, haga scroll hasta el punto en el que estaba antes de cargar la página
    // esto sólo funcionará en la página de equipo
    //
    this.jumpAtEnter = 0;
    barba.hooks.before( ( data ) => {
        // console.log( data );
        this.jumpAtEnter = 0;
        if ( data.trigger.dataset ) {
          if ( 'jumpTo' in data.trigger.dataset ) {
            if ( data.current.container.querySelector( 'div[data-jump-after-barba]') ) {
              this.jumpAtEnter = document.documentElement.scrollTop || document.body.scrollTop
            }
          }
        }
    })
    barba.hooks.afterEnter( ( data ) => {
      if ( 0 !== this.jumpAtEnter ) {
        // He de esperar 100 milisegundos, porque si ejecuto el scroll sin espera, no funciona
        setTimeout( function() {
          window.scroll( 0, this.jumpAtEnter )
        }.bind( this ) , 100 )
      }
    })
    //
    // Fin de la modificación para el autoposicionamineto tras la navegación por el menú de equipo
    //

    barba.init({
      debug: true,
      cacheIgnore: false,
      prefetchIgnore: false,
      timeout: 5000,
      transitions: [
        {
          name: 'default',
          leave ({ current, next, trigger }) {
            window.scrollTo(0, 0)
            current.container.classList.remove('barba-container--fadein')
            current.container.classList.toggle('barba-container--fadeout')
            setTimeout(() => {
              this.async()
            }, 400)
          },
          enter ({ current, next, trigger }) {
            // console.log( current );
            // console.log( next );
            next.container.classList.remove('barba-container--fadeout')
            next.container.classList.toggle('barba-container--fadein')
          },
          after ({ current, next, trigger }) {
            if ( window.layout ) {
              window.layout.init();
            }

/*
            //
            // Busco la función executeAtEnterPage y si existe, la ejecuto, es allí donde están todos los scripts
            // necesarios para que la página funcione
            //
            var funcion = new Array;
            funcion.push( window.findFunction( 'executeAtEnterPage', next ) );
            //
            // Es necesario esperar un poco a ejecutar el script, pues sino no existe y no lo ejecuta
            //
            if ( funcion.length > 0 ) {
              setTimeout(() => {
                //
                // Inicio de nuevo todas las animaciones y el cursor
                //
                if ( window.layout ) {
                  window.layout.init();
                }
                if ( window.customMouse ) {
                  window.customMouse.init();
                }
                window.lottie();
                window.colapsable();
                if ('' != window.location.hash) {
                  jumpToAnchor(window.location.hash.substr( 1 ) );
                }
                funcion.forEach( function( func ) {
                  if ( '' != func ) {
                    eval( func );
                  }
                });
              }, 500);
            }
*/
          }
        }
      ],
      prevent: ({ el }) => {
        const ignoreClasses = ['ab-item', 'another-class-here'] // Additional (besides .js-barba-prevent) ignore links with these classes (ab-item is for wp admin toolbar links)
        if (/.pdf/.test(el.href.toLowerCase())) {
          return true
        }
        if (el.classList && el.classList.contains('js-barba-prevent')) {
          return true
        }
        if (el.dataset && el.dataset.lightbox) {
          return true
        }
        for (let i = 0; i < ignoreClasses.length; i++) {
          if (el.classList.contains(ignoreClasses[i])) {
            return true
          }
        }
      },
      requestError: (trigger, action, url, response) => {
        // console.log('REQUEST ERROR')
        // console.log(url)
        // console.log(response)
      }
    })
    // Global enter hook
    barba.hooks.enter(({ current, next, trigger }) => {
      window.requestAnimationFrame(() => {
        this.setBodyClasses(next.container)
        this.setTranslationLinks(next.container)
        this.mobileMenu()
        this.updateCurrentHighlightedMenu(next.html)
        this.fixWpAdminBar()
        window.modulesLoader.loadModules()
        window.lazyLoading.loadImages()
        // Google Analytics
        if (typeof window.ga === 'function') {
          window.ga('send', 'pageview', window.location.pathname)
        }
        // Animations
//        setTimeout(() => { this.setAnimationScenes(new ScrollMagic.Controller()) }, 600)
      })
    })
  }

  setBodyClasses (e) {
    const skipClasses = ['no-cursor']
    const i = e.querySelector('#body-classes').value
    const o = i.split(' ')
    const currentBodyClasses = document.querySelector('body').classList
    Array.from(currentBodyClasses).forEach(bodyClass => {
      // Don't remove class if it exists in skipClasses
      if (!skipClasses.includes(bodyClass)) {
        currentBodyClasses.remove(bodyClass)
      }
    })
    for (let s = 0; s < o.length; s++) {
      document.querySelector('body').classList.add(o[s])
    }
  }

  setTranslationLinks (e) {
    'use strict'
    const linksData = e.getAttribute('data-translation-links')
    if (linksData) {
      const links = JSON.parse(linksData)
      if (links.constructor === Array && links.length > 0) {
        for (let s = 0; s < links.length; s++) {
          const li = document.querySelector('.gld-mainMenu__lang ul li:nth-child(' + (s + 1) + ') a')
          if (li !== null) {
            li.setAttribute('href', links[s])
          }
        }
      }
    }
  }

  mobileMenu () {
    // Uncheck the main menu toggler.
    const menuToggler = document.getElementById('main-menu-toggler')
    if (menuToggler) { menuToggler.checked = false }
    // Uncheck the language switcher toggler.
    const languagesToggler = document.getElementById('languages-toggler')
    if (languagesToggler) { languagesToggler.checked = false }
  }

  /**
   * Parses the DOM structure of the BarbaJS fetch, and applies same classes on the header in DOM.
   * @param {String} htmlString - The new page html in string format from barbaJs
   */
  updateCurrentHighlightedMenu (htmlString) {
    const html = new window.DOMParser().parseFromString(htmlString, 'text/html')
    if ('body' in html === false || html.body.childNodes.length < 1) { // Why the length check?
      return
    }
    // First remove current classes from all menu items and sub menus.
    for (const el of Array.from(document.querySelectorAll('.menu-item'))) {
      el.classList.remove('current-menu-item')
      el.classList.remove('current-menu-parent')
      el.classList.remove('current-menu-ancestor')
    }
    // Get the new current menu items and parents and set the appropiate classes.
    let newEl
    for (const el of Array.from(html.querySelectorAll('.current-menu-item'))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`)
        if (newEl !== null) {
          newEl.classList.add('current-menu-item')
        }
      }
    }
    for (const el of Array.from(html.querySelectorAll('.current-menu-parent'))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`)
        if (newEl !== null) {
          newEl.classList.add('current-menu-parent')
        }
      }
    }
    for (const el of Array.from(html.querySelectorAll('.current-menu-ancestor'))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`)
        if (newEl !== null) {
          newEl.classList.add('current-menu-ancestor')
        }
      }
    }
  }

  fixWpAdminBar () {
    if (!document.querySelector('#wpadminbar')) {
      return
    }
    const bodyFormData = new window.FormData()
    bodyFormData.set('action', 'miura_fix_barbajs_wp_admin')
    bodyFormData.set('location', window.location.href)
    axios.post(window.scripts_ajax_variables.ajax_url, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
      if (!response.data) {
        return
      }
      if (response.data && response.data.success === true) {
        const adminMenuLinks = Array.from(document.querySelector('.ab-top-menu').querySelectorAll('a'))
        for (const adminLink of adminMenuLinks) {
          const mregex = /post=(\d+)&/g
          adminLink.classList.add('js-barba-prevent')
          const href = adminLink.getAttribute('href')
          const groups = mregex.exec(href)
          if (groups && groups.length >= 2) {
            adminLink.setAttribute('href', href.replace(groups[1], response.data.pageId))
          } else {
            const chunks = href.split('?url=')
            if (chunks.length === 2) {
              adminLink.setAttribute('href', chunks[0] + '?url=' + encodeURIComponent(window.location.href))
            }
          }
        }
      }
    }).catch(error => {
      // console.warn('Error calling admin-ajax: ')
      // console.warn(error)
    })
  }

/*  
  setAnimationScenes (controller) {
    // Set animation classes to all the elements that will use them.
    for (const el of Array.from(
      document.querySelectorAll(
        '.call-to-action, .hero, .featured-content, .seal-hero, .grid__card, .archive-our-products__header, .archive-recipe__header, .content__item, .timeline'
      )
    )) {
      el.classList.add('animatable')
      new ScrollMagic.Scene({
        triggerElement: el,
        triggerHook: 0.75,
        reverse: false
      })
        .setClassToggle(el, 'animated')
        .addTo(controller)
    }
  }
*/
}

export default Barba
