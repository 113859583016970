class LoginForm {
	constructor() {
		if (document.querySelector('#login-form-submit-login')) {
			//
			// Estoy en el formulario de login
			//
			
			// Capturo eventos de los botones
			document.querySelector('#login-form-submit-login').addEventListener('click', function () { LoginForm.validateData() });
			document.querySelector('#login-form-submit-recover').addEventListener('click', function () { LoginForm.recoverPassword() });

			// Capturo eventos de recover password y volver al login
			document.querySelector('#login-form-recover-password-1').addEventListener('click', function () { LoginForm.prepareRecoverPasswordForm() });
			document.querySelector('#login-form-submit-return-login-1').addEventListener('click', function () { LoginForm.prepareLoginForm() });
			document.querySelector('#login-form-recover-password-2').addEventListener('click', function () { LoginForm.prepareRecoverPasswordForm() });
			document.querySelector('#login-form-submit-return-login-2').addEventListener('click', function () { LoginForm.prepareLoginForm() });

			// Prevent form submit
			document.querySelector('#login-form-form').addEventListener('submit', function (e) { e.preventDefault() })
			LoginForm.prepareLoginForm();
		} else {
			//
			// Estoy en el área privada
			//

			// Capturo el logout del investor area
			if (document.querySelector('#investor-area-logout-1')) {
				document.querySelector('#investor-area-logout-1').addEventListener('click', function () { LoginForm.logOut() });
				document.querySelector('#investor-area-logout-2').addEventListener('click', function () { LoginForm.logOut() });
			}

			if (document.querySelector('#save-data-button')) {
				
				LoginForm.checkPasswords();

				document.querySelector('#edit-data-password').addEventListener('change', function () { LoginForm.checkPasswords() });
				document.querySelector('#edit-data-password-2').addEventListener('change', function () { LoginForm.checkPasswords() });

				document.querySelector('#cancel-data-button').addEventListener('click', function () { LoginForm.backToEdit() });
			}
		}
	}

	static prepareLoginForm() {
		// Cambio el título
		document.querySelector( '#login-form-title' ).innerHTML = document.querySelector( '#login-form-title-login' ).value;
		// Cambio el label del username
		document.querySelector( '#login-form-user-label' ).innerHTML = document.querySelector( '#login-form-label-login' ).value;
		// Muestro el campo de password
		document.querySelector('#login-form-password').style.display = 'inline-block';
		// Muestro el label del password
		document.querySelector('#login-form-password-label').style.display = 'inline-block';
		// Muestro el enlace a "Recuperar contraseña"
		document.querySelector('#login-form-recover-password-1').style.display = 'inline-block';
		document.querySelector('#login-form-recover-password-2').style.display = 'inline-block';
		// Oculto el enlace a "Volver al login"
		document.querySelector( '#login-form-submit-return-login-1' ).style.display = 'none';
		document.querySelector( '#login-form-submit-return-login-2' ).style.display = 'none';
		// Muestro el botón de submit para login del formulario y oculto el de recuperar password
		LoginForm.changeButtonStatus('submit-login', 'enable');
		LoginForm.changeButtonStatus('submit-recover', 'disable');
		LoginForm.changeButtonStatus('submit-return-login', 'disable');
	}

	static prepareRecoverPasswordForm() {
		// Cambio el título
		document.querySelector( '#login-form-title' ).innerHTML = document.querySelector( '#login-form-title-recover' ).value;
		// Cambio el label del username
		document.querySelector( '#login-form-user-label' ).innerHTML = document.querySelector( '#login-form-label-recover' ).value;
		// Oculto el campo de password
		document.querySelector('#login-form-password').style.display = 'none';
		// Oculto el label del password
		document.querySelector('#login-form-password-label').style.display = 'none';
		// Oculto el enlace a "Recuperar contraseña"
		document.querySelector( '#login-form-recover-password-1' ).style.display = 'none';
		document.querySelector( '#login-form-recover-password-2' ).style.display = 'none';
		// Muestro el enlace a "Volver al login"
		document.querySelector( '#login-form-submit-return-login-1' ).style.display = 'inline-block';
		document.querySelector( '#login-form-submit-return-login-2' ).style.display = 'inline-block';
		// Muestro el botón de submit para recuperar password del formulario y oculto el de login
		LoginForm.changeButtonStatus('submit-login', 'disable');
		LoginForm.changeButtonStatus('submit-recover', 'enable');
		LoginForm.changeButtonStatus('submit-return-login-1', 'enable');
		LoginForm.changeButtonStatus('submit-return-login-2', 'enable');
	}

	static backToEdit() {
		var elem = document.querySelector('#save-data');
		elem.parentNode.removeChild(elem);
		document.querySelector('form-datos-personales').submit();		
	}

	static checkPasswords() {
		var enable = false;
		if ('' == document.querySelector('#edit-data-password').value || '' == document.querySelector('#edit-data-password-2').value) {
			enable = true;
		} else {
			if (document.querySelector('#edit-data-password').value != document.querySelector('#edit-data-password-2').value) {
				enable = true;
			}
		}
		document.querySelector('#save-data-button').disabled = enable;
		document.querySelector('#save-data-button').readonly = enable;
	}

	static logOut() {
		document.cookie = 'loginInvestoAreaSession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		window.location.href = '/investor-area/';
	}

	static changeButtonStatus(id, type) {
		var element = document.querySelector('#login-form-' + id);
		if (element) {
			var element2 = element;
			if ('A' != element.tagName) {
				element = element.parentElement;
			}
			if ('enable' == type) {
				element.style.display = 'inline-block';
				element2.disabled = false;
			} else {
				element.style.display = 'none';
				element2.disabled = true;
			}
		}
	}

	static recoverPassword( e ) {
		// Get the form parameters
		var action = 'recover_password'
		var user = document.querySelector( '#login-form-user' )
		var message = document.querySelector( '#login-form-error' )
		message.classList.remove( 'visible' );
		message.innerHTML = '';
		if ( LoginForm.validateEmail( user.value ) ) {
			// Ajax form submit
			var xmlhttp = LoginForm.ajaxReq()
			var url = encodeURI( scripts_ajax_variables.ajax_url ) // eslint-disable-line
			var params = '';
			params += 'action=' + action;
			params += '&user=' + user.value;
			xmlhttp.open( 'POST', url, true ) // set true for async, false for sync request
			xmlhttp.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' )
			xmlhttp.send( params ) // or null, if no parameters are passed

			// Validation
			xmlhttp.onreadystatechange = function () {
				if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
					try {
						message.classList.remove( 'visible' );
					} catch (error) {
						// Show notification
						message.classList.add( 'visible' );
						message.innerHTML = 'Error: ' +  xmlhttp.statusText;
					}
				}
				LoginForm.PrepareLoginForm();
				// Enable and stop the submit button animation
//				evt.srcElement.disabled = false
//				evt.srcElement.classList.remove( 'validating' )	
			}
//			document.querySelector( '#login-form-iframe-recover-password' ).src = 'https://miuraequity.com/recuperarcontrasenya.php?param=' + user.value;
		} else {
			message.classList.add( 'visible' );
			message.innerHTML = document.querySelector( '#login-form-error-recover-txt' ).value;
		}

	}   

	static validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	static ajaxReq () {
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest() // eslint-disable-line
		} else if (window.ActiveXObject) {
			return new ActiveXObject( 'Microsoft.XMLHTTP' ) // eslint-disable-line
		} else {
			console.log( 'Browser does not support XMLHTTP.' )
			return false
		}
	}

/*

	static getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	static eraseCookie(name) {   
		document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

*/
	static setCookie(cname, cvalue, exdays) {
		const d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		let expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	static getCookie(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for(let i = 0; i <ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	static validateData() {
		// Get the form parameters
		var action = 'check_login'
		var user = document.querySelector( '#login-form-user' )
		var password = document.querySelector('#login-form-password')
		var login_button = document.querySelector('#login-form-submit-login')
	
		// Disable and animate the submit button
		login_button.disabled = true
		login_button.classList.add( 'validating' )

		// Hide last error message
		var message = document.querySelector( '#login-form-error' )
		message.classList.remove( 'visible' );
		message.innerHTML = '';
  
		// Ajax form submit
		var xmlhttp = LoginForm.ajaxReq()
		var url = encodeURI(scripts_ajax_variables.ajax_url) // eslint-disable-line
		var params = '';
		params += 'action=' + action;
		params += '&user=' + user.value;
		params += '&password=' + password.value;
		xmlhttp.open( 'POST', url, true ) // set true for async, false for sync request
		xmlhttp.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' )
		xmlhttp.send( params ) // or null, if no parameters are passed

		// Validation
		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
				try {
					var data = JSON.parse(xmlhttp.responseText)
//					console.log( data );
//					console.log( '/documento?session=' + data.message )
					if (data.valid) {
						// Creo la cookie para que sea reconocido más tarde
						LoginForm.setCookie('loginInvestoAreaSession', data.message, 1);
						// Redirijo
						window.location.href = '/investor-area/';
/*
						window.location.href = '/documento?session=' + data.message;
						document.querySelector( '#login-form' ).classList.remove( 'visible' );
						document.querySelector( '#login-form-user' ).value = '';
						document.querySelector( '#login-form-password' ).value = '';
*/
					} else {
						// Borro la cookie
						document.cookie = 'loginInvestoAreaSession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
						// Muestro el mensaje de error
						message.classList.add( 'visible' );
						message.innerHTML = data.message;
					}
				} catch (error) {
					// Show notification
					message.classList.add( 'visible' );
					message.innerHTML = 'Error: ' +  xmlhttp.statusText;
				}
			}
			// Enable and stop the submit button animation
			login_button.disabled = false
			login_button.classList.remove( 'validating' )	
		}
	}
}
export default LoginForm