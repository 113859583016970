import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import Swiper from 'swiper';

class Layout {

    constructor() {
        this.init();
    }

    init() {
        gsap.registerPlugin( ScrollTrigger,DrawSVGPlugin );

        this.mobileBreakpointChecker=this.mobileBreakpointChecker.bind(this);
        this._mobileBreakpoint = window.matchMedia( '(min-width:992px)' );

        this.destroy();

        this._mobileBreakpoint.addListener(this.mobileBreakpointChecker);

        this.createVideoParallax();
        this.createNumberCounterAnimation();
        this.createImageParallax();
        this.createBackgroundLinesAnimation();
        this.mobileBreakpointChecker();

        Layout.jumpToAnchor();

        //
        // Esto lo que hace es capturar el evento del click justo antes de lanzar nada
        // si detecto que es la misma página, y tiene un hashtag (etiqueta para los amigos)
        // como no lanza nada de barba y recoloca todo según el navegador, parte del texto quedará
        // debajo del menú superior, por tanto, he de forzar que se lance el "jumpToAnchor"
        //
        Array.prototype.forEach.call(document.querySelectorAll('a[href]'), (e) => {
            if (!e.classList.contains('js-jumptoanchor-prevent')) {
                e.addEventListener('click', (e2) => {
                    if (e2.currentTarget.href === window.location.href) {
                        var url = new URL(e2.currentTarget.href);
                        if ('' != url.hash && '#' != url.hash) {
                            Layout.jumpToAnchor();
                        }
                    }
                });
            }
        });

        //
        // Menú del Area Privada del Inversor
        //
        Layout.APIMenu( '.api-menu-titulo' );
        Layout.APIMenu( '.api-menu-capital-titulo' );
        var urlParams = new URLSearchParams( window.location.search );
        var elem = '';
        if ( urlParams.get( 'f' ) ) {
            elem = document.querySelector( '#api-' + urlParams.get( 'f' ) );
            if ( elem ) {
                elem.click();
            }
        }
        if ( urlParams.get( 'e' ) ) {
            elem = document.querySelector( '#api-' + urlParams.get( 'e' ) );
            if ( elem ) {
                elem.click();
            }
        }
    }

    static APIMenu( clase ) {
        //
        // Menú del Area Privada del Inversor
        //
        Array.prototype.forEach.call(document.querySelectorAll(clase), (e) => {
            document.querySelector('#' + e.dataset['controlMenu']).style.display = 'none';
            e.classList.add('investor-area-hidden');
            e.style.cursor = 'pointer';
            e.addEventListener( 'click', ( e ) => {
                var div = document.querySelector( '#' + e.target.dataset[ 'controlMenu' ] ).style;
                if ( 'none' == div.display ) {
                    div.display = 'block';
                    e.target.classList.add('investor-area-show');
                    e.target.classList.remove('investor-area-hidden');
                } else {
                    div.display = 'none';
                    e.target.classList.remove('investor-area-show');
                    e.target.classList.add('investor-area-hidden');
                }
            });
        });
    }

    destroy() {

        this._mobileBreakpoint.removeListener(this.mobileBreakpointChecker);

        this.destroySliders();
        this.destroyNumberCounterAnimation();
        this.destroyImageParallax();
        this.destroyVideoParallax();
        this.destroyBackgroundLinesAnimation();
    }

    static jumpToAnchor() {
        if ( '' != window.location.hash ) {
            if (document.readyState == 'complete') {
                setTimeout( function() { Layout.junpToAnchor_Delayed(); }, 100 );
            } else {
                document.onreadystatechange = function () {
                    if (document.readyState === "complete") {
                        Layout.jumpToAnchor();
                    }
                }
            }
        }
    }

    static getTop( e ){
        var offset=e.offsetTop;
        if(e.offsetParent!=null) offset+=Layout.getTop(e.offsetParent);
        return offset;
    }

    static junpToAnchor_Delayed() {
        if ( '' != window.location.hash ) {
            var id = window.location.hash;
            if ( -1 != id.indexOf( '?' ) ) {
                id = id.substr( 0, id.indexOf( '?' ) )
            }
            // var scrollTo = document.querySelector( id ).offsetTop - document.querySelector( '.gld-mainMenu' ).offsetHeight;
            var scrollTo = Layout.getTop( document.querySelector( id ) ) - 160;
            scroll({ top: scrollTo , behavior: 'smooth' });
        }
    }

    formatNumber(value) {

        const lang=document.documentElement.lang;
        switch (lang) {
            case "en-US":
                return  value
                        .toFixed(0)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                break;
            case "es-ES":
                return value
                      .toFixed(0)
                      .replace('.', ',') 
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                break;
            default:
        }

    }

    mobileBreakpointChecker() {
        
        this.createSliders(this._mobileBreakpoint.matches);
    }

    createSliders(destroy=false) {
        
        const sliders=document.querySelectorAll('.swiper-container');
        sliders.forEach( (slider, i) => {
            if(destroy) {
                if(this._sliders[i]) this._sliders[i].destroy(true,true);
            }
            else {
                this._sliders.push( new Swiper (slider, {
        
                    slidesPerView: 'auto',
                    spaceBetween: "10%",
                    keyboardControl: true,
                    grabCursor: true,
                    centeredSlides: true,
                    loop: true,
                    breakpoints: {
                        320: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        480: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                      }
                    })
                );
            }
        })

        if(destroy) this._sliders=[];
      };

    destroySliders() {

        if(this._sliders) {
            this._sliders.forEach(
                 slider => {
                     slider.destroy(true,true);
                     slider=null;
                 }
 
            )
        }
        this._sliders=[];
    }

    createNumberCounterAnimation() {

        let self=this;
        let numberData=[];
        let domNumbers= gsap.utils.toArray(".numeros-animados-encolumnados .animated-number .numero");
        domNumbers=domNumbers.filter( element =>  !isNaN(element.textContent) );

        this._numberCounterTL=gsap.timeline({
            scrollTrigger: {
                trigger: ".numeros-animados-encolumnados",
                toggleActions: "play none none reset",
                start: "top 90%",
                end: "bottom 60%",
                // scrub: true,
                // onLeaveBack: ()=> {
                //     domNumbers.forEach( domNumber => domNumber.textContent="0" )
                //     }
                }
            }
        )

        domNumbers.forEach( (domNumber,i) => {
            numberData[i]={var:0, domElement:domNumber};
            let offset= i===0? "" :  "-=0.5";
            let finalValue=domNumber.textContent;
            domNumber.textContent="0";
            this._numberCounterTL.to(
                numberData[i], 
                // {
                //     var: 0
                // },
                {
                    var: finalValue, 
                    duration: 1, 
                    ease:"Power1.easeInOut",
                    onUpdate: function() {
                        this.targets()[0].domElement.textContent=(self.formatNumber(this.targets()[0].var));
                    }
                },
                offset
            )
        })
    }

    destroyNumberCounterAnimation() {

        if( this._numberCounterTL ) {
            this._numberCounterTL.kill();
            this._numberCounterTL=null;
        }
    }

    createImageParallax() {

        if ( document.querySelector( '.imagen-fondo-con-texto .image-wrapper' ) ) {
            this._parallaxImage=gsap.timeline({
                scrollTrigger: {
                    trigger: ".imagen-fondo-con-texto",
                    start: "top bottom",
                    end: "bottom top",
                    scrub: true,
                    // markers: true
                    }
                }
            );
            this._parallaxImage.from(".imagen-fondo-con-texto .image-wrapper", {duration: 15, y:"-50%", ease:"none"})
        }
    }

    destroyImageParallax() {

        if( this._parallaxImage ) {
            this._parallaxImage.kill();
            this._parallaxImage=null;
        }
    }

    createVideoParallax() {

        if ( document.querySelector( '.header-con-video video' ) ) {
            this._parallaxVideo=gsap.timeline({
                scrollTrigger: {
                    trigger: ".header-con-video",
                    start: "top center",
                    end: "bottom top",
                    scrub: true,
                    // markers: true
                    }
                }
            );
            this._parallaxVideo.from(".header-con-video video", {duration: 5, y:"-20%", ease:"none"})
        }
    }

    destroyVideoParallax() {

        if( this._parallaxVideo ) {
            this._parallaxVideo.kill();
            this._parallaxVideo=null;
        }
    }

    createBackgroundLinesAnimation() {

        // gsap.utils.toArray(".lineas-terreno").forEach( lines => { 
        //     gsap.from(lines,
        //             {
        //                 scrollTrigger: {
        //                     trigger: lines,
        //                     start: "top 40%",
        //                     end: "bottom top",
        //                     scrub: true,
        //                     // markers: true
        //                 },
        //                 ease: "none",
        //                 y:"-50%"
        //             }
        //         )
        //     }
        // )

        gsap.utils.toArray(".lineas-terreno").forEach( lines => { 

            this._backgroundLinesAnimationsArray.push( gsap.fromTo(lines.querySelectorAll("path"),
                        {
                            drawSVG:"0%", 
                            visibility:"visible"
                        },
                        {
                            drawSVG:"100%", 
                            ease: "none",
                            scrollTrigger: {
                            trigger: lines,
                            start: "top 80%",
                            end: "top 30%",
                            // start: "top bottom",
                            // end: "top 40%",
                            scrub: true,
                            // markers: true
                            },
                        },
                    )
                )
            }
        )
    }

    destroyBackgroundLinesAnimation() {

        if(this._backgroundLinesAnimationsArray) {
            this._backgroundLinesAnimationsArray.forEach(
                 animation => {
                     animation.kill();
                     animation=null;
                 }
 
            )
        }
        this._backgroundLinesAnimationsArray=[];
    }
}

export default Layout;