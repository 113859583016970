import { gsap } from "gsap";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

class MainMenu {

  constructor() {

    // gsap.registerPlugin( DrawSVGPlugin );

    this._DOM_MAP= {
      body: document.querySelector('body'),
      menu: document.querySelector('.gld-mainMenu'),
      container: document.querySelector('.gld-mainMenu__container'),
      options: document.querySelector('.gld-mainMenu__options'),
      langButtonToogle: document.querySelector('.gld-mainMenu__langToggler'),
      burgerButton: document.querySelector('.gld-mainMenu__burger'),
      burgerIcon: document.querySelector('.gld-mainMenu__burgerIcon'),
      closeIcon: document.querySelector('.gld-mainMenu__closeIcon'),
      desktopCloseButton: document.querySelector('.gld-mainMenu__desktopCloseIcon'),
      // backgroundLines: document.querySelector(".gld-mainMenu__lines"),
      investorAreaButton: document.querySelector('.gld-mainMenu__option.--golden-button'),
      submenuContainer:  document.querySelector('.gld-mainMenu__suboptionsContainer'),
      goBackList:  document.querySelectorAll('.gld-mainMenu .gld-mainMenu__goBack'),
      langButton: document.querySelector('.gld-mainMenu__lang'),
      LangListDialog: document.querySelector('.gld-mainMenu__langList'),
      suboptionsList: document.querySelectorAll('.gld-mainMenu__article a, .gld-mainMenu__suboptionsContainer .gld-mainMenu__suboption .gld-mainMenu__option, .gld-mainMenu__articleSeeAll'),
      langList: gsap.utils.toArray( document.querySelectorAll('.gld-mainMenu__langElement') ),
      optionList: gsap.utils.toArray( document.querySelectorAll('.gld-mainMenu__options .gld-mainMenu__option:not(.--golden-button)') ),
      submenuList: gsap.utils.toArray( document.querySelectorAll('.gld-mainMenu__suboptionsContainer .gld-mainMenu__suboption') )
    }

    this._MATCH_MEDIA='(min-width: 1200px)';

    this.scrollController= new MainMenuScrollController();

    this._inicializeAppropiateMenu( window.matchMedia(`${this._MATCH_MEDIA}`) );
    // this._iniViewportHeightCheck();
    this._iniCheckWidth();

  }

  // _iniViewportHeightCheck() {

  //   this._setVhCssVariable();
  //   window.addEventListener( 'resize', this._setVhCssVariable );
  // }

  // _setVhCssVariable() {

  //   let vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // }

  _iniCheckWidth() {

    this._inicializeAppropiateMenu=this._inicializeAppropiateMenu.bind(this);
    this._matchMedia= window.matchMedia((`${this._MATCH_MEDIA}`));
    this._matchMedia.addListener( this._inicializeAppropiateMenu );
  }

  _inicializeAppropiateMenu( matchMedia ) {

    if(this._currentMenu) {

      this._currentMenu.destroy();
    }
    this._currentMenu= matchMedia.matches? new DesktopMenu(this._DOM_MAP, this.scrollController) : new MobileMenu(this._DOM_MAP);
  }

}

class DesktopMenu {

  constructor(domMap, scrollController) {

    this._domMap=domMap;
    this._scrollController=scrollController;
    this._opened=false;
    this._domElementsToAnimate=undefined;
    this._gsapTween=undefined;
    this._linesTween=undefined;
    this._previousScreen= -1;
    this._currentScreen=-1;

    this._hideScreenCompleteHandler=this._hideScreenCompleteHandler.bind(this);
    // this._hideLinesCompleteHandler=this._hideLinesCompleteHandler.bind(this); // WIP

    if(this._domMap.langButton) this._langDialog=new LangDialog( this._domMap );

    this._initDesktopCloseButton();
    this._initButtonsListeners();
  }

  destroy(deeper=true) {

    this._destroyGsapTween();

    this._domMap.optionList.forEach( (element, i) => {

      element.removeAttribute('gold-data-id');
      element.classList.remove("--selected");
      element.removeEventListener("click",  this._optionClickHandler);
      }
    )


    this._domMap.suboptionsList.forEach( (element, i) => {

      element.removeEventListener("click",  this._suboptionClickHandler);
      }
    )

    this._domMap.goBackList.forEach( (element, i) => {

      element.removeEventListener("click",  this._goBackButtonClickHandler);
      }
    )

    this._domMap.submenuList.forEach( (element, i) => {

      element.style.display="none";
      }
    )

    this._destroyDesktopCloseButton();

    if(deeper) {

      this._scrollController.start();
      this._stopMouseControl();
      // this._hideLines();
      this._domMap.submenuContainer.style.display=null;
      // this._domMap.backgroundLines.classList.remove("--show");
      // this._domMap.submenuContainer.classList.remove("--show");
      this._domMap.menu.classList.remove("--opened");
      this._domMap.body.classList.remove("--menu-opened");
      // this._domMap.langButtonToogle.checked=false;

      if(this._langDialog) {
        this._langDialog.destroy(true);
        this._langDialog=null;
      }
    }

  }

  _initButtonsListeners() {

    this._optionClickHandler=this._optionClickHandler.bind(this);
    this._domMap.optionList.forEach( (element, i) => {

        element.setAttribute('gold-data-id', i)
        element.addEventListener("click",  this._optionClickHandler);
      }
    );

    this._suboptionClickHandler=this._suboptionClickHandler.bind(this);
    this._domMap.suboptionsList.forEach( (element, i) => {

      element.addEventListener("click",  this._suboptionClickHandler);
      }
    );

  }

  _initDesktopCloseButton() {

    this._desktopCloseButtonClickHandler=this._desktopCloseButtonClickHandler.bind(this);
    this._domMap.desktopCloseButton.addEventListener( "click", this._desktopCloseButtonClickHandler );
  }

  _destroyDesktopCloseButton() {

    this._domMap.desktopCloseButton.removeEventListener( "click", this._desktopCloseButtonClickHandler );
  }

  _desktopCloseButtonClickHandler(e) {

    this._previousScreen=this._currentScreen;
    this._close();
    this._hideScreen();
  }

  _optionClickHandler(e) {


    // this._previousScreen=this._currentScreen;
    // this._currentScreen=e.currentTarget.getAttribute("gold-data-id");

    if( e.currentTarget.classList.contains("gld-direct-link") ) {

      if(this._opened) this._suboptionClickHandler(e);
      return;
    };

    e.preventDefault();

    this._previousScreen=this._currentScreen;
    this._currentScreen=e.currentTarget.getAttribute("gold-data-id");

    if(!this._opened) {

      this._destroyGsapTween();
      this._scrollController.stop();
      // this._showlines()
      this._initializeMouseControl();
      this._opened=true;
      this._domMap.menu.classList.add("--opened");
      this._domMap.body.classList.add("--menu-opened");
      this._markOptionAsSelected(this._currentScreen, true);
    }
    else if(this._currentScreen===this._previousScreen) {

     this._close()
    }
    else {
      this._markOptionAsSelected(this._previousScreen, false);
      this._markOptionAsSelected(this._currentScreen, true);

    }

    this._hideScreen();
  }

  _suboptionClickHandler(e) {

    this._previousScreen=this._currentScreen;
    this._close();
    this._hideScreen();
  }


  _close() {

    this._markOptionAsSelected(this._currentScreen, false);
    this._opened=false;
    this._currentScreen=-1;
    this._scrollController.start();
    this._stopMouseControl();
    // this._hideLines();
    this._domMap.menu.classList.remove("--opened");
    this._domMap.body.classList.remove("--menu-opened");
  }


  _markOptionAsSelected(numOption, isSelected) {

    var domOption= document.querySelector(`.gld-mainMenu__option[gold-data-id="${numOption}"]`);
    isSelected? domOption.classList.add("--selected") : domOption.classList.remove("--selected");
  }

  _initializeScreen() {

    if( this._currentScreen!==-1 ) { // Si hay que visualizar una pantalla.

      this._domMap.submenuContainer.style.display="flex";
      this._domMap.submenuList[this._currentScreen].style.display="flex";

      let domSubmenu=undefined;
      this._domElementsToAnimate=[];

      if( this._domMap.submenuList[this._currentScreen].classList.contains("--articles") ) { // Noticias
        domSubmenu=this._domMap.submenuList[this._currentScreen];
        this._domElementsToAnimate.push( gsap.utils.toArray( domSubmenu.querySelectorAll('.gld-mainMenu__description, .gld-mainMenu__articleImage, p, .gld-mainMenu__articleDesc, .gld-mainMenu__articleSeeAll')) );
      }
      else {
        domSubmenu=this._domMap.submenuList[this._currentScreen];
        this._domElementsToAnimate.push( domSubmenu.querySelectorAll('.gld-mainMenu__description') );
        this._domElementsToAnimate.push( gsap.utils.toArray( domSubmenu.querySelectorAll('.gld-mainMenu__option')) );
      }

      this._showScreen();
    }
  }

  _showScreen() {

    this._destroyGsapTween();

    this._gsapTween= gsap.fromTo(this._domElementsToAnimate,
      { y:-30, autoAlpha: 0 },
      { duration: 0.3, y: 0, autoAlpha: 1, stagger: 0.1,  onReverseComplete: this._hideScreenCompleteHandler },
      );
  }

  _hideScreen() {

    if(this._gsapTween)  {
      this._gsapTween.duration(0.1).reverse();
    }
    else if(this._opened) {
      this._initializeScreen();
    }
  }

  _hideScreenCompleteHandler() {

    this._domMap.submenuContainer.style.display="none";
    this._domMap.submenuList[this._previousScreen].style.display="none";
    this._initializeScreen();
  }

  // _showlines() {

  //   this._domMap.backgroundLines.classList.add("--show");

  //   this._linesTween=gsap.fromTo(document.querySelectorAll(".gld-mainMenu__lines path"),
  //                   {
  //                       drawSVG:"0%",
  //                       visibility:"hidden",
  //                       // opacity: 0
  //                   },
  //                   {
  //                       drawSVG:"100%",
  //                       visibility:"visible",
  //                       ease: "none",
  //                       duration: 5,
  //                       // opacity: 1,
  //                       delay: 0.5,
  //                       onReverseComplete: this._hideLinesCompleteHandler // WIP
  //                   },
  //               )

  // }

  // _hideLines() {

  //   if(this._linesTween) {
  //     this._linesTween.duration(0.1).reverse();
  //   }
  // }

  // _hideLinesCompleteHandler() {  // WIP

  //   this._domMap.backgroundLines.classList.remove("--show"); // WIP
  // } // WIP

  _destroyGsapTween() {

    if(this._gsapTween) {
      this._gsapTween.kill();
      this._gsapTween=undefined;
    }
  }

  _initializeMouseControl() {

    this._mousemoveHandler=this._mousemoveHandler.bind(this);
    this._startMouseControl();
  }


  _startMouseControl() {

    window.addEventListener("mousemove", this._mousemoveHandler);
  }

  _stopMouseControl() {

    window.removeEventListener("mousemove", this._mousemoveHandler);
  }

  _mousemoveHandler(e) {

    if(e.clientY-100>this._domMap.menu.offsetHeight) {
      this._previousScreen=this._currentScreen;
      this._close();
      this._hideScreen();
    }
  }

}

class LangDialog {

  constructor(domMap) {

    this._domMap=domMap;
    this._opened=false;


    // this.resizeHandler=this._throttle(this.resizeHandler.bind(this));
    this.resizeHandler=this.resizeHandler.bind(this);

    this._langButtonHandler=this._langButtonHandler.bind(this);
    this._domMap.langButtonToogle.addEventListener("click", this._langButtonHandler);
  }

  destroy(deeper=true) {

    if(this._observer) {
      this._observer.disconnect();
      this._observer=null;
      window.removeEventListener("resize", this.resizeHandler);
    }

    if(deeper) {
      this._domMap.langButtonToogle.removeEventListener("click", this._langButtonHandler);
      this._domMap.langButtonToogle.checked=false;
    }
  }

  _langButtonHandler(e) {

    this._opened=!this._opened;
    if(this._opened) {
      this._setPosition();
      this._init();
    }
    else {
      this.destroy(false);
    }
  }

  _init() {

    this._mutationObserverHandler=this._mutationObserverHandler.bind(this)
    this._observer = new MutationObserver(this._mutationObserverHandler);
    let config = { attributes: true, attributeFilter:["class"] };
    this._observer.observe( this._domMap.menu, config );

    window.addEventListener("resize", this.resizeHandler);
  }

  _mutationObserverHandler(mutationsList) {

    for(var mutation of mutationsList) {

      let y=( mutation.target.classList.contains("scrolled") || mutation.target.classList.contains("scrolled--show"))? 60 : 80;
      this._domMap.LangListDialog.style.top=y+"px";
    }
  };

  resizeHandler(e) {

    this._setPosition();
  }

  _setPosition() {

    let p=this._getScreenCordinates( this._domMap.langButton );
    this._domMap.LangListDialog.style.left=p.x-17+"px";
    this._domMap.LangListDialog.style.top=p.y+26+"px";
  }

  _getScreenCordinates(obj) {
    var p = {};
    p.x = obj.offsetLeft;
    p.y = obj.offsetTop;
    while (obj.offsetParent) {
        p.x = p.x + obj.offsetParent.offsetLeft;
        p.y = p.y + obj.offsetParent.offsetTop;
        if (obj == document.getElementsByTagName("body")[0]) {
            break;
        }
        else {
            obj = obj.offsetParent;
        }
    }
    return p;
}

  _throttle(fn, threshhold) {

    // registra el último tiempo de ejecución
    var last
    // temporizador
    var timer
    // el intervalo predeterminado es de 250 ms
    threshhold || (threshhold = 250)
    // La función devuelta ejecuta la función fn cada tres milisegundos
    return function () {
      // Guarde el contexto y los parámetros de la llamada a la función y páselo a fn
      var context = this
      var args = arguments
      var now = +new Date()
      // Si el tiempo transcurrido desde la última ejecución de la función fn es menor que el umbral, entonces abandone
      // ejecuta fn y reinicia el tiempo
      if (last && now < last + threshhold) {
        clearTimeout(timer)
        // Garantía de ejecutar fn nuevamente después del final del intervalo de tiempo actual
        timer = setTimeout(function () {
          last = now
          fn.apply(context, args)
        }, threshhold)
      // ejecuta fn una vez al comienzo del intervalo de tiempo y cuando se alcanza el intervalo especificado
      } else {
        last = now
        fn.apply(context, args)
      }
    }
  }
}

class MobileMenu {

  constructor(domMap) {

    this._domMap=domMap;
    this._opened=false;
    this._domElementsToAnimate=undefined;
    this._gsapTween=undefined;
    this._linesTween=undefined;
    this._previousScreen= 0;
    this._currentScreen=-1;

    this._animationCompleteHandler=this._animationCompleteHandler.bind(this);
    this._hideScreenCompleteHandler=this._hideScreenCompleteHandler.bind(this);

    this._burgerIconClickHandler=this._burgerIconClickHandler.bind(this);
    this._domMap.burgerButton.addEventListener("click", this._burgerIconClickHandler );

    this._goBackButtonClickHandler= this._goBackButtonClickHandler.bind(this);

    // this._initOptionsListeners();
  }

  destroy(deeper=true) {

    this._destroyGsapTween();

    this._domMap.optionList.forEach( (element, i) => {

      element.removeAttribute('gold-data-id');
      element.removeEventListener("click",  this._optionClickHandler);
      }
    )

    this._domMap.suboptionsList.forEach( (element, i) => {

      element.removeEventListener("click",  this._suboptionClickHandler);
      }
    )

    this._domMap.goBackList.forEach( (element, i) => {

      element.removeEventListener("click",  this._goBackButtonClickHandler);
      }
    )

    this._domMap.submenuList.forEach( (element, i) => {

      element.style.display="none";
      }
    )

    this._domMap.submenuContainer.classList.remove("--show");
    this._domMap.burgerButton.classList.remove("--show-x");


    if(deeper) {

      this._domMap.optionList.forEach( (element, i) => {

        this._restartAnimatedElement( element );
        }
      )

      this._restartAnimatedElement( this._domMap.investorAreaButton );

      // this._hideLines();
      // this._domMap.backgroundLines.classList.remove("--show");
      this._domMap.burgerButton.removeEventListener("click", this._burgerIconClickHandler );
      this._domMap.options.style.pointerEvents=null;
      this._domMap.submenuContainer.classList.remove("--show");
      this._domMap.menu.classList.remove("--opened");
      this._domMap.body.classList.remove("--menu-opened");
    }
  }

  _restartAnimatedElement( domElement ) {

    if( domElement ) {
      domElement.style.opacity=null;
      domElement.style.visibility=null;
      domElement.style.transform=null;
    }
  }

  _initOptionsListeners() {

    this._optionClickHandler=this._optionClickHandler.bind(this);
    this._domMap.optionList.forEach( (element, i) => {

        element.setAttribute('gold-data-id', i)
        element.addEventListener("click",  this._optionClickHandler);
      }
    );

    this._suboptionClickHandler=this._suboptionClickHandler.bind(this);

    this._domMap.suboptionsList.forEach( (element, i) => {

      element.addEventListener("click",  this._suboptionClickHandler);
      }
    );
  }

//   _markOptionAsSelected(isSelected, numOption=null) {
// // console.log(isSelected)

//     if(isSelected) {
//       let domOption= document.querySelector(`.gld-mainMenu__option[gold-data-id="${numOption}"]`);
//       domOption.classList.add("--selected");
//     }
//     else{
//       let domOption= document.querySelector('.gld-mainMenu__options .gld-mainMenu__option.--selected');
//       if( domOption) domOption.classList.remove("--selected");
//     }
//   }

  _optionClickHandler(e) {


    if( e.currentTarget.classList.contains("gld-direct-link") ) {
      this._suboptionClickHandler(e);
      return;
    };

    e.preventDefault();

    this._previousScreen=this._currentScreen;
    this._currentScreen=e.currentTarget.getAttribute("gold-data-id");
    // this._markOptionAsSelected( false );
    // this._markOptionAsSelected( true, this._currentScreen );
    this._domMap.options.style.pointerEvents="none";
    this._hideScreen();
  }

  _suboptionClickHandler(e) {

    this._close();
    this.destroy(false)
  }

  _burgerIconClickHandler(e) {

    this._close();

    if(this._opened) {
      this._initOptionsListeners();
      this._initializeScreen();
      // this._showlines()
    }
    else {
      this.destroy(false)
    }
  }

_close() {

    this._opened=!this._opened;
    // this._hideLines();
    this._destroyGsapTween();
    this._previousScreen=0;
    this._currentScreen=-1;
    this._domMap.menu.classList.toggle("--opened");
    this._domMap.body.classList.toggle("--menu-opened");
    this._domMap.burgerButton.classList.toggle("--show-x");
}

  _initializeScreen() {

    if(this._previousScreen===-1) { // Si la pantalla anterior era el menú principal
      this._domMap.submenuList[this._currentScreen].style.display="flex";
      this._domMap.submenuContainer.classList.add("--show");
      // this._domMap.submenuList[this._currentScreen].classList.add("--show");

    }
    else
    {
      if(this._previousScreen) {
        this._domMap.goBackList[this._previousScreen].removeEventListener("click", this._goBackButtonClickHandler);
      }
      if(this._currentScreen) {
        this._domMap.submenuList[this._previousScreen].style.display="none";
        this._domMap.submenuContainer.classList.remove("--show");
        // this._domMap.submenuList[this._previousScreen].classList.remove("--show");
      }
    }

    let domSubmenu=undefined;
    this._domElementsToAnimate=[];

    if(this._currentScreen===-1)
    {
      this._domMap.options.style.pointerEvents="auto";
      this._domElementsToAnimate= [...this._domMap.optionList];
      this._domElementsToAnimate.push( this._domMap.investorAreaButton);
      this._domElementsToAnimate.push( ...this._domMap.langList );
    }
    else if( this._domMap.submenuList[this._currentScreen].classList.contains("--articles") ) { // Noticias
      this._domMap.goBackList[this._currentScreen].addEventListener("click", this._goBackButtonClickHandler);
      domSubmenu=this._domMap.submenuList[this._currentScreen];
      this._domElementsToAnimate.push( gsap.utils.toArray( domSubmenu.querySelectorAll('.gld-mainMenu__goBack, .gld-mainMenu__articleImage, p, .gld-mainMenu__articleDesc, .gld-mainMenu__articleSeeAll, .gld-mainMenu__description')) );
    }
    else {
      this._domMap.goBackList[this._currentScreen].addEventListener("click", this._goBackButtonClickHandler);
      domSubmenu=this._domMap.submenuList[this._currentScreen];
      this._domElementsToAnimate.push( gsap.utils.toArray( domSubmenu.querySelectorAll('.gld-mainMenu__goBack, .gld-mainMenu__option')) );
      this._domElementsToAnimate.push( domSubmenu.querySelectorAll('.gld-mainMenu__description') );
    }

    this._showScreen();
  }

  _showScreen() {

    this._destroyGsapTween();

    this._gsapTween= gsap.fromTo(this._domElementsToAnimate,
      { y:-10, autoAlpha: 0 },
      { duration: 0.4, y: 0, autoAlpha: 1, stagger: 0.1, onComplete: this._animationCompleteHandler, onReverseComplete: this._hideScreenCompleteHandler },
      );
  }

  _hideScreen() {

    if( this._gsapTween ) this._gsapTween.duration(0.5).reverse();
  }

  // _showlines() {

  //   this._domMap.backgroundLines.classList.add("--show");

  //   this._linesTween=gsap.fromTo(document.querySelectorAll(".gld-mainMenu__lines path"),
  //                   {
  //                       drawSVG:"0%",
  //                       visibility:"hidden",
  //                       // opacity: 0
  //                   },
  //                   {
  //                       drawSVG:"100%",
  //                       visibility:"visible",
  //                       ease: "none",
  //                       duration: 5,
  //                       // opacity: 1,
  //                       delay: 0.5
  //                   },
  //               )

  // }

  // _hideLines() {

  //   if(this._linesTween) {
  //     this._linesTween.duration(0.1).reverse();
  //   }
  // }

  _animationCompleteHandler() {}

  _hideScreenCompleteHandler() {

    this._initializeScreen();
  }

  _goBackButtonClickHandler(e) {

    e.preventDefault();
    this._previousScreen=this._currentScreen;
    this._currentScreen=-1;
    e.currentTarget.removeEventListener("click", this._goBackButtonClickHandler);
    this._hideScreen();
  }

  _destroyGsapTween() {

    if(this._gsapTween) {
      this._gsapTween.kill();
      this._gsapTween=undefined;
    }
  }
}

class MainMenuScrollController {

  constructor() {

    this.initController()
  }

  initController () {

    this._header = document.querySelector('.gld-mainMenu');
    this._headerHeight = this._header.offsetHeight + 88
    this._distanceToTop  = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

    this._callback=this._throttle(this._callback.bind(this),200);

    this.start();

    if (this._distanceToTop > 0) {
      this._header.classList.add('scrolled')
    }
  }

  start() {

    this.stop();
    window.addEventListener('scroll', this._callback );
  }

  stop() {

    window.removeEventListener('scroll', this._callback );
  }

  _throttle (callback, wait = 100) {

    let timer = null

    return function (args) {
      if (timer === null) {
        timer = setTimeout(() => {
           callback.apply(this, args)
          timer = null
        }, wait)
      }
    }
  }

  _callback () {

      const newDistanceToTop  = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

      if (newDistanceToTop === 0) {
        this._header.classList.remove('scrolled--transition')
        this._header.classList.remove('scrolled', 'scrolled--past', 'scrolled--show')
        return
      }

      if (newDistanceToTop > this._headerHeight) {
        this._header.classList.add('scrolled--past')
        let self=this;
        setTimeout(function () { self._header.classList.add('scrolled--transition') }, 99)
      }

      if (newDistanceToTop < this._distanceToTop) {
        this._header.classList.add('scrolled--show')
      } else {
        this._header.classList.add('scrolled')
        this._header.classList.remove('scrolled--show')
      }

      this._distanceToTop = newDistanceToTop
  }

}

export default MainMenu;
