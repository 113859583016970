import './scss/style.scss'
import Barba from './js/lib/barba.js'
import ModulesLoader from './js/modules-loader.js'
import Cookies from './js/template-parts/popups/cookies.js'
import Layout from "./js/template-parts/layout";
import MainMenu from "./js/template-parts/common/main-menu"


setVhCssVariable();
window.addEventListener( 'resize', setVhCssVariable );

function setVhCssVariable() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', function (event) {
  // BarbaJS 
  window.barba = new Barba()

//  window.barba.setAnimationScenes(new ScrollMagic.Controller())
})

window.addEventListener('load', function (event) {
  // Header
  window.layout = new Layout();
  window.mainMenu = new MainMenu();
  ////window.header = new Header()

  // Modules loader
  window.modulesLoader = new ModulesLoader()
  window.modulesLoader.loadModules()

  // Cookies banner
  window.cookies = new Cookies()

  window.findFunction = function( fname, next ) {
    var funcion = '';
    var num_open = -1;
    var start = next.html.indexOf( 'function ' + fname );
    if ( -1 != start ) {
      for( var n = 0; num_open != 0; n++ ) {
        if ( '{' == next.html.substr( start + n, 1 ) ) {
          if ( -1 == num_open ) {
            num_open = 0;
          }
          num_open++;
        }
        if ( '}' == next.html.substr( start + n, 1 ) ) {
          num_open--;
        }
  
        if ( num_open > 0 ) {
          funcion += next.html.substr( start + n, 1 );
        }
      }
    }
    if ( '' != funcion ) {
      funcion = funcion.substr( 1 ).trim();
    }
    return funcion;
  }
/*
  if ( document.querySelector( '#suscribe-button' ) ) {
    document.querySelector( '#suscribe-button' ).addEventListener( 'click', function(){
      document.getElementsByName('first_name')[0].value = '';
      document.getElementsByName('last_name')[0].value = '';
      document.getElementsByName('company_name')[0].value = '';
      document.getElementsByName('email')[0].value = '';
      document.getElementsByName('email')[0].classList.remove('show-error');
      document.getElementsByName('privacy_policy')[0].value = 'unchecked';
      if ( document.querySelector('#privacy-policy-checkbox-msg') ) {
        var elem = document.querySelector('#privacy-policy-checkbox-msg');
        elem.parentNode.removeChild(elem);
      }
      if ( document.querySelector('#contact-form-email-msg') ) {
        var elem = document.querySelector('#contact-form-email-msg');
        elem.parentNode.removeChild(elem);
      }
      document.querySelector( '#contact-form' ).classList.add( 'opened' );
      document.querySelector( '#contact-form-opacity' ).classList.add( 'opened' );
      document.querySelector('body').classList.add('--menu-opened');
//      document.querySelector( '#contact-form' ).style.display = 'block';
//      document.querySelector( '#contact-form-opacity' ).style.display = 'block';
    });
    document.querySelector( '#close-modal-subscription' ).addEventListener( 'click', function() {
      document.querySelector( '#contact-form' ).classList.remove( 'opened' );
      document.querySelector( '#contact-form-opacity' ).classList.remove( 'opened' );
      document.querySelector('body').classList.remove('--menu-opened');
//      document.querySelector( '#contact-form' ).style.display = 'none';
//      document.querySelector( '#contact-form-opacity' ).style.display = 'none';
      return false;
    });
  }

  var heroVideoContainer = document.querySelector( '.boton-video' );

  // Lightbox
  if (heroVideoContainer !== undefined) {
    let videoPlayButton = document.querySelector( '.boton-video' );
    if (window.fslightbox === undefined) {
      window.modulesLoader.loadJS('/wp-content/themes/kcimpact/js/vendor/fslightbox.js', () => {
      })
    } else {
      refreshFsLightbox() // eslint-disable-line
    }

    // Autoplay popup videos
    if ( videoPlayButton ) {
      videoPlayButton.addEventListener( 'click', function() {
        if ( !document.querySelectorAll('.fslightbox-container video') ) {
          refreshFsLightbox();
        }
        fsLightboxInstances[''].props.onOpen = function (fsLightbox) { // eslint-disable-line
          return playWhenReady();
        }
      })
    }
  }

  function playWhenReady() {
    if ( document.querySelectorAll('.fslightbox-container video')[0] ) {
      document.querySelectorAll('.fslightbox-container video')[0].play();
    } else {
      setTimeout( playWhenReady, 500 );
    }
    return true;
  }
*/
  
})
